import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/JyKJ-Xy25gY">
        <SEO title="Setting Goals - The Big 10" />
    </Layout>
)

export default SermonPost
